<template>
    <div class="mx-auto container container--fluid">
            <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
                <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
                <v-btn dark text @click="snackbar = false">Close</v-btn>
            </v-snackbar>
         <v-toolbar  dense class="tc-title">
             <router-link to="student-social"  class="nav-arrow-left"><v-icon class="left-arrow" >mdi-arrow-left-bold</v-icon></router-link>
                <v-toolbar-title>Employability Test Type</v-toolbar-title>
                 <v-spacer></v-spacer>
                <template>
                    <v-btn color="primary" class="btn-title mx-2" fab small dark  @click="dialog=true"><v-icon>mdi-plus</v-icon></v-btn>
                     <router-link to="student-profile" class="nav-arrow-right"><v-icon class="right-arrow">mdi-forward</v-icon></router-link>
                </template>
         </v-toolbar>

         <v-row>
         <v-col cols="12" sm='3' v-for="item in emptest_list" :key="item">
                <v-card style=" margin: 8px; height:100%">
                    <v-card-title class="c-title">
                           {{item.employabilitytesttype}}
                    </v-card-title>
                    <v-card-text>
                          <v-row style="margin: 0px !important; padding: 0px !important">    
                            <v-col cols="6" sm="6" style="margin: 0px !important; padding: 0px !important">
                                <span class="lbl">Academic Year</span>
                            </v-col>
                            <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important">
                                : {{item.ay}}
                            </v-col>
                        </v-row>
                        <v-row style="margin: 0px !important; padding: 0px !important">    
                            <v-col cols="6" sm="6" style="margin: 0px !important; padding: 0px !important">
                                <span class="lbl">Semester</span>
                            </v-col>
                            <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important">
                                : {{item.sem}}
                            </v-col>
                        </v-row> 
                       
                       <v-row style="margin: 0px !important; padding: 0px !important">    
                            <v-col cols="6" sm="6" style="margin: 0px !important; padding: 0px !important">
                                <span class="lbl">Score</span>
                            </v-col>
                            <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important"> 
                                : {{item.score}}
                            </v-col>
                        </v-row> 
                       
                    </v-card-text>
                </v-card>
            </v-col>
         </v-row>
         <!-- <v-app id="inspire" class>
            <template v-if="load==true">
                <v-data-table :headers="headers" :items="emptest_list" sort-by="employabilitytesttype" class="elevation-1" :search="search">
                    <template v-slot:top>
                        <v-toolbar flat color="white">
                            <v-toolbar-title>Employability Test Type</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
                            <span style="width: 20px;"></span>  
                           -->
                            <v-dialog v-model="dialog" max-width="500px">
                               <!-- <template v-slot:activator="{ on }">
                                    <v-btn color="indigo darken-4" dark class="mb-2" v-on="on">New Employability Test Type</v-btn>
                                </template> -->
                                
                                <v-card>
                                    <v-card-title class="c-title">
                                        <span class="headline">{{ formTitle }}</span>
                                    </v-card-title>

                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12">
                                                    <label>Select Test Type<span class="required">*</span> :</label>
                                                    <v-select
                                                        dense
                                                        v-model="editedItem.employabilitytesttype"
                                                        :items="test_list"
                                                        :readonly="editedIndex==-1?false:true"
                                                        item-text="employabilitytesttype"
                                                        item-value="id"
                                                        persistent-hint
                                                        :filled="editedIndex==-1?false:true"
                                                        :error="errorMsg.employabilitytesttype?true:false"
                                                        :error-messages="errorMsg.employabilitytesttype?'Required':''"
                                                        return-object
                                                        single-line
                                                        :rules="[v => !!v || 'required']"
                                                    ></v-select>
                                                </v-col>
                                                <v-col cols="12" v-if="editedIndex != -1">
                                                    <label>Test Type<span class="required">*</span> :</label>
                                                    <v-select dense v-model="editedItem.employabilitytesttype" :items="test_list" item-value="id" persistent-hint :error="errorMsg.employabilitytesttype?true:false" :error-messages="errorMsg.employabilitytesttype?'Required':''" :rules="[v => !!v || 'required']"></v-select>
                                                </v-col>

                                                <v-col cols="12">
                                                    <label>Select Academic Year<span class="required">*</span> :</label>
                                                    <v-autocomplete
                                                        v-model="editedItem.ay"
                                                        :readonly="editedIndex==-1?false:true"
                                                        item-text="ay"
                                                        item-value="id"
                                                        persistent-hint
                                                        :filled="editedIndex==-1?false:true"
                                                        :error="errorMsg.ay?true:false"
                                                        :error-messages="errorMsg.ay?'Required':''"
                                                        return-object
                                                        single-line
                                                        :items="ay_list"
                                                        :rules="[v => !!v || 'required']"
                                                    ></v-autocomplete>
                                                </v-col>
                                                <v-col cols="12" v-if="editedIndex != -1">
                                                    <label>Academic Year<span class="required">*</span> :</label>
                                                    <v-select dense v-model="editedItem.ay" :items="ay_list" item-value="id" persistent-hint :error="errorMsg.ay?true:false" :error-messages="errorMsg.ay?'Required':''" :rules="[v => !!v || 'required']"></v-select>
                                                </v-col>

                                                <v-col cols="12">
                                                    <label>Select Semester<span class="required">*</span> :</label>
                                                    <v-select
                                                        dense
                                                        v-model="editedItem.sem"
                                                        :items="sem_list"
                                                        :readonly="editedIndex==-1?false:true"
                                                        item-text="sem"
                                                        item-value="id"
                                                        persistent-hint
                                                        :filled="editedIndex==-1?false:true"
                                                        :error="errorMsg.sem?true:false"
                                                        :error-messages="errorMsg.sem?'Required':''"
                                                        return-object
                                                        single-line
                                                        :rules="[v => !!v || 'required']"
                                                    ></v-select>
                                                </v-col>
                                                <v-col cols="12" v-if="editedIndex != -1">
                                                    <label>Semester<span class="required">*</span> :</label>
                                                    <v-select dense v-model="editedItem.sem" :items="sem_list" item-value="id" persistent-hint :error="errorMsg.sem?true:false" :error-messages="errorMsg.sem?'Required':''" :rules="[v => !!v || 'required']"></v-select>
                                                </v-col>
                                                 <v-col cols="12" sm="12" md="12">
                                                    <label>Enter Score<span class="required">*</span> :</label>
                                                    <v-text-field v-model="editedItem.score" persistent-hint :hint="editedIndex==-1?'':''" :error="errorMsg.sem?true:false" :error-messages="errorMsg.score?'Required':''" type="number" :rules="[v => !!v || 'required']">
                                                </v-text-field>
                                            </v-col>
                                            </v-row>
                                            <v-row dense>
                                                
                                                <v-col>
                                                    <input type="hidden" v-model="editedItem.id" name="id" />
                                                    <!-- <v-hidden v-model="editedItem.id" label="id"></v-hidden> -->
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                                        <v-btn color="primary darken-1" @click="save">Save</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                       <!-- </v-toolbar> -->
                   <!-- </template>
                    <template v-slot:item.isactive="{ item }">
                        <span v-if="item.isactive">
                            <v-icon class="mr-2 v-icon-size" color="success">mdi-check-circle-outline</v-icon>
                        </span>
                        <span v-else>
                            <v-icon class="mr-2 v-icon-size" color="error">mdi-close-circle-outline</v-icon>
                        </span>
                     </template>
                </v-data-table>
            </template> 
        </v-app> -->
    </div>
</template>

<script>
    import axios from "axios";
    export default {
        data: () => ({
            errorMsg: {
                employabilitytesttype: false,
                sem: false,
                ay: false,
            },
            snackbar_msg: "",
            color: "",
            snackbar: false,
            search: "",
            load: false,
            dialog: false,
            emptest_list: null,
            test_list: null,
            sem_list: null,
            ay_list: null,
            headers: [
                {
                    text: "Test Type",
                    value: "employabilitytesttype",
                },
                {
                    text: "Academic Year",
                    value: "ay",
                },
                {
                    text: "Semester",
                    value: "sem",
                },
              
            ],

            editedIndex: -1,
            editedItem: {
                id: "",
                employabilitytesttype: "",
                sem: "",
                ay: "",
               score:'',
            },
            defaultItem: {
                id: "",
                employabilitytesttype: "",
                sem: "",
                ay: "",
                score:'',
               
            },
        }),

        computed: {
            formTitle() {
                return this.editedIndex === -1 ? "New Employability Test Type" : "Edit Employability Test Type";
            },
        },

        watch: {
            dialog(val) {
                val || this.close();
            },
        },
        mounted() {
            axios
                .post("/LearnerData/learneremployability")
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.emptest_list = res.data.emptest_list;
                        this.test_list = res.data.test_list;
                        this.sem_list = res.data.sem_list;
                        this.ay_list = res.data.ay_list;
                        this.load = true;
                    } else {
                    }
                })
                .catch((error) => {
                   // window.console.log(error);
                });
        },

        methods: {
            initializeErrorMsgFalse() {
                this.errorMsg.employabilitytesttype = false;
                this.errorMsg.sem = false;
                this.errorMsg.ay = false;
                 this.errorMsg.score = false;
            },
            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
            editItem(item) {
                this.editedIndex = this.emptest_list.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialog = true;
            },

            close() {
                this.dialog = false;
                this.initializeErrorMsgFalse();
                setTimeout(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            },

            save() {
                
                    if (this.isValidated()) {
                        axios
                            .post("/LearnerData/saveemployability", this.editedItem)
                            .then((res) => {
                                if (res.data.status.code == "SUCCESS") {
                                    this.emptest_list.push(this.editedItem);
                                    this.showSnackbar("#4caf50", " Employability Test Type Added Successfully..."); // show snackbar on success
                                } else if (res.data.status.code == "NA") {
                                    this.showSnackbar("#b71c1c", " Employability Test Type Already Present!!!"); // show snackbar on error
                                } else if (res.data.status.code == "DE") {
                                    this.showSnackbar("#b71c1c", "You are not allowed to do multiple registrations in same Academic-Year and Semester!");
                                }
                            })
                            .catch((error) => {
                                //window.console.log(error);
                            });

                        this.close();
                    }
                },
            

            isValidated() {
                if (this.editedItem.employabilitytesttype && this.editedItem.ay && this.editedItem.sem &&this.editedItem.score) {
                    return true;
                } else {
                    if (!this.editedItem.employabilitytesttype) {
                        this.errorMsg.employabilitytesttype = true;
                        this.showSnackbar("#b71c1c", " Select Test Type... ");
                    }
                    else if (!this.editedItem.ay) {
                        this.errorMsg.ay = true;
                        this.showSnackbar("#b71c1c", " Select Academic Year... ");
                    }
                    else if (!this.editedItem.sem) {
                        this.errorMsg.sem = true;
                        this.showSnackbar("#b71c1c", " Select Semester... ");
                    }
                     else if (!this.editedItem.score) {
                        this.errorMsg.score = true;
                        this.showSnackbar("#b71c1c", " Enter Score... ");
                    }
                    return false;
                }
            },
        },
    };
</script>
<style scoped>
.nav-arrow-left{
        margin-right: 5px;
        text-decoration: none;
        color: #FFF;
    }
    .nav-arrow-right{
        margin: 5px;
        text-decoration: none;
        color: rgb(3, 55, 167);
    }
    .left-arrow{
        color: #FFF;
    }
    .right-arrow{
        color: rgb(3, 55, 167);
    }
.ec-title{
        background-image: linear-gradient(-100deg, #f4faba, #d64907);
        color: #FFF;
    }
    .c-title{
        background-image: linear-gradient(-90deg, #befcf7, #03948d);
        color: #FFF;
    }
    .tc-title{
        background-image: linear-gradient(-90deg, #FFF, #057996);
        color: #FFF;
        border-radius: 3px;
    }
    .btn-title{
        background-image: linear-gradient(-100deg, #8aa5f7, #063bdb);
        color: #FFF;
    }
    .card-title{
         background-image: linear-gradient(-90deg, #8edffd, #034c70);
        color: #FFF;
    }
    .lbl{
        color: #1705e6;
    }
    .v-text-field {
        padding-top: 0px;
        margin-top: 0px;
    }

    .v-icon-size {
        font-size: 20px;
    }

    .edit-avatar {
        transition: 0.3s;
        border: 1px solid #b0bec5;
    }

    .edit-avatar:hover {
        background-color: #b0bec5;
    }

    .edit-v-icon {
        padding-left: 6px;
        font-size: 15px;
    }

    .edit-v-icon:hover {
        color: white;
    }
      .required{
  color:red;
  font-size:14px;
  font-style:bold;
  }
</style>
